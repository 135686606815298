import React, { useContext } from "react"
import PropTypes from "prop-types"

import Results from "./results"
import SearchContext from "../context/SearchContext"


export default function Related({ id }) {
  const { isDefault } = useContext(SearchContext)
  if (isDefault) {
    return <span/>
  }
  return (
    <div className="mt-5">
      <Results id={id}/>
    </div>
  )
}
Related.propTypes = {
  id: PropTypes.string.isRequired,
  related: PropTypes.arrayOf(
    PropTypes.string.isRequired
  )
}
