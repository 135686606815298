import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { useMediaQuery } from "@react-hook/media-query"
import Layout from "../layouts"
import stripHtml from "../libs/strip-html"
import BackButton from "../components/back-button"
import BackgroundSection from "../components/background-section"
import Related from "../components/related"

export default ({ data }) => {
  const mobile = useMediaQuery("only screen and (max-width: 769px)")
  const { work, genres, originals, formats, targets } = data
  const [author] = work.author
  const tags = [
    ...originals.nodes.filter(({ id }) => work.original.includes(id)),
    ...genres.nodes.filter(({ id }) => work.genre.includes(id)),
    ...formats.nodes.filter(({ id }) => work.format.includes(id)),
    ...targets.nodes.filter(({ id }) => work.target.includes(id))
  ]
    .map(({ name }) => name)
    .sort((a, b) => a.localeCompare(b))
  const head = [
    work.preview,
    work.length,
    work.location,
    work.language
  ].filter(s => s && s.length > 0)
  return (
    <Layout title={work.title} description={stripHtml(work.excerpt)}>
      <BackgroundSection
        image={work.featured_media.localFile.childImageSharp.fluid}
      >
        <div className="container is-widescreen">
          <div className="head">
            <BackButton/>
            <h1 className="title is-1 has-text-primary mb-1">{work.title}</h1>
            {/*HIDE THE GHOST AUTHOR*/}
            {author.id !== 201 && (
              <Link to={`/authors/${author.slug}`} className="subtitle is-4 has-text-white">
                {author.title}
              </Link>
            )}
            <p>
              {head.join(" | ")}
            </p>
          </div>
        </div>
        {mobile && <Img fluid={work.featured_media.localFile.childImageSharp.fluid} fadeIn/>}
        <div className="container is-widescreen">
          <div className="columns">
            <div className="column is-6">
              {work.content && (
                <>
                  <h2 className="title is-5 has-text-primary mb-1">Synopsis</h2>
                  <div className="content" dangerouslySetInnerHTML={{ __html: work.content }}/>
                </>
              )}
              {tags.length > 0 && (
                <>
                  <h2 className="title is-5 has-text-primary mb-1">Tags</h2>
                  <div className="content">
                    <p>
                      {tags.join(", ")}
                    </p>
                  </div>
                </>
              )}
              {work.material && (
                <div>
                  <h2 className="title is-5 has-text-primary mb-1">Material available</h2>
                  <div className="content">
                    <p>
                      {work.material}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Link to={`/contact-us?title=${work.title}`} className="button is-rounded is-primary is-large action-button">
            <span className="icon is-medium">
              <i className="far fa-comment"/>
            </span>
            <span>Request Material</span>
          </Link>
        </div>
      </BackgroundSection>
      <Related id={work.id}/>
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        work: wordpressWpWork(slug: {eq: $slug}) {
            id: wordpress_id
            title
            content
            slug
            length
            material
            language
            location
            preview
            genre
            target
            format
            original
            author: artist {
                id: wordpress_id
                title: post_title
                slug: post_name
            }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(
                            maxWidth: 2000
                        ) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        originals: allWordpressWpOriginal {
            nodes {
                id: wordpress_id
                name
            }
        }
        genres: allWordpressWpGenre {
            nodes {
                id: wordpress_id
                name
            }
        }
        formats: allWordpressWpFormat {
            nodes {
                id: wordpress_id
                name
            }
        }
        targets: allWordpressWpTarget {
            nodes {
                id: wordpress_id
                name
            }
        }
    }
`