import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

const StyledWrapper = styled.div`
  margin-bottom: 1em;
`
export default function BackButton() {
  return (
    <StyledWrapper>
      <button type="button" className="button is-primary is-rounded" onClick={() => navigate(-1)}>
        <span className="icon is-medium">
          <i className="fas fa-arrow-left"/>
        </span>
        <span>Back</span>
      </button>
    </StyledWrapper>
  )
}
